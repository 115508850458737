@import './common/sizes';

.container {
  height: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include medium {
    height: 83px;
  }

  @include small {
    height: auto;
    padding: 12px 0px;
    flex-wrap: wrap;
  }
}

.logoWrapper {
  width: 168px;
  height: 70px;

  @include medium {
    width: 140px;
    height: 58px;
  }

  @include small {
    width: 104px;
    height: 43px;
  }
}
