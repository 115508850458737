@import './common/colours';
@import './common/sizes';

.container {
  background-color: $ultra-light-blue;
  padding-top: 60px;
  padding-bottom: 100px;

  .flexContainer {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .title {
    @include small {
      display: none;
    }
  }

  .smallTitle {
    display: none;

    @include small {
      display: block;
    }
  }
}

.subTitle {
  font-weight: 700;
  line-height: 103%;
  font-size: 30px;
  margin-bottom: 10px;

  @include large {
    font-size: 28px;
  }

  @include medium {
    font-size: 22px;
  }
}

.steps {
  padding-left: 25px;
  color: $text-magenta;
  font-weight: 700;
}

.altBlue {
  color: $dark-blue;
  font-weight: 400;
}

.summaryWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: $light-blue;
  border-radius: 5px;
  margin-left: 30px;
}

.summary {
  font-weight: 700;
}
