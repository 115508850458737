@import './common/colours';
@import './common/sizes';

.container {
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 80px;

  @include small {
    padding: 20px;
  }

  @media (max-width: 369px) {
    box-shadow: none;
    padding: 20px 0;
  }
}

.paragraph {
  font-size: 19px;
}

.input {
  width: 100%;
  height: 44px;
  border: 2px solid rgba(23, 36, 53, 0.7);
  box-sizing: border-box;
  font-size: 16px;
  color: $dark-blue;
  padding-left: 10px;
}

.dropdown {
  width: 100%;
  height: 44px;
  border: 2px solid rgba(23, 36, 53, 0.7);
  font-size: 16px;
  padding-left: 10px;
  color: $dark-blue;
  box-sizing: border-box;
}
