@import './common/colours';
@import './common/sizes';

.container {
  background-image: url('../assets/1440_bgd@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 732px;

  @include large {
    height: 650px;
  }

  @include medium {
    background-image: url('../assets/834_bgd@2x.jpg');
    height: 550px;
    background-position: right;
  }

  @include small {
    background-image: url('../assets/1440_bgd@2x.jpg');
    height: 1000px;
    background-position: right;
  }
}

.left,
.right {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;

  .logoWrapper {
    width: 106px;
    height: 106px;

    @include medium {
      width: 86px;
      height: 86px;
    }
  }
}

.headingText {
  flex: 1;
  font-size: 44px;
  line-height: 103%;
  color: $text-blue;
  padding-left: 17px;
  margin-top: 0;
  margin-bottom: 0;

  @include large {
    font-size: 36px;
  }

  @include medium {
    font-size: 28px;
  }

  @include small {
    font-size: 24px;
  }
}

.subTitle {
  font-size: 31px;
  font-weight: 700;
  margin-bottom: 0;

  @include large {
    font-size: 28px;
  }

  @include medium {
    font-size: 22px;
  }
}

.iconContainer {
  display: flex;
  gap: 20px;

  .icon {
    width: 206px;
    height: 59px;

    @include medium {
      width: 160px;
      height: 46px;
    }
  }
}

.phoneWrapper {
  height: 732px;
  width: 585px;

  @include large {
    height: 650px;
    width: 519px;
  }

  @include medium {
    height: 525px;
    width: 419px;
  }

  @include small {
    height: 428px;
    width: 342px;
    margin: auto;
    transform: translateX(-30px);
  }
}
