@mixin small {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin large {
  @media (max-width: 1260px) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: 1261px) {
    @content;
  }
}
