@import './common/colours';

.container {
  position: absolute;
  background-color: $white;
  top: 78px;
  border: 2px solid rgba(23, 36, 53, 0.7);
}

.list {
  font-size: 18px;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 3px 15px;

    &:hover {
      cursor: pointer;
      background-color: $ultra-light-blue;
    }
  }
}

.subText {
  padding-left: 5px;
  font-size: 16px;
  opacity: 0.6;
}
