@import './styles/common/colours.scss';
@import './styles/common/sizes.scss';

body {
  color: $dark-blue;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 148%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include large {
    font-size: 20px;
  }

  @include medium {
    font-size: 16px;
  }
}

h2,
h3 {
  font-size: 42px;
  color: $text-blue;
  line-height: 105%;

  @include medium {
    font-size: 32px;
  }

  @include small {
    font-size: 26px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#rc-anchor-container {
  width: 250px !important;
}
