@import './common/colours';
@import './common/sizes';

.common {
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  background-color: $button-pink;
  padding: 10px 25px;
  font-size: 20px;

  @include medium {
    font-size: 16px;
  }

  @include small {
    font-size: 14px;
    padding: 7px 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: $dark-blue;
  }
}

.secondary {
  border: 2px solid $dark-blue;
  border-radius: 5px;
  color: $dark-blue;
  background-color: transparent;

  &:hover {
    color: $button-pink;
    border-color: $button-pink;
    background-color: transparent;
  }
}
